import BigNumber from "bignumber.js/bignumber";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

export const SUPPORTED_NETWORK_IDS = [137, 80001];

export const INFINITE =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";

// // Testnet
// export const MAINNET_CHAINID = 80001;
// export const STAKING_POOL_CONFIG = {
//   id: "0x13FDf92411eE2Bc19cf1F00c15a82A69a81daB28",
//   withdrawalFee: "0",
//   harvestInterval: "60",
//   owner: "0x4274A49FBeB724D75b8ba7bfC55FC8495A15AD1E",
//   inputToken: {
//     name: "USDC",
//     symbol: "USDC",
//     decimals: "6",
//     address: "0x671b68fb02778D37a885699dA79c13Faf0d3C560",
//     tokenURL:
//       "https://cryption-network-local.infura-ipfs.io/ipfs/QmYcftrjFV4qRGixg8FZekc4siPndaQyYX1oJoJ1U9ie2g",
//     price: "0.35",
//   },
//   rewardToken: {
//     name: "B4Real Credits",
//     symbol: "B4RC",
//     decimals: "18",
//     address: "0x625D8DcfAE53C3fba7670b82bF1A7c2C689C416F",
//     tokenURL:
//       "https://cryption-network-local.infura-ipfs.io/ipfs/QmbCguTQzatdB3ebFVBq43B36g24e1DTSvPqw6YEpRG1ug",
//     startBlock: "",
//     endBlock: "",
//     rewardsPerToken: "0.5",
//     expectedAPR: "30",
//     price: "0.70",
//   },
// };

export const MAINNET_CHAINID = 137;
export const STAKING_POOL_CONFIG = {
  id: "0x668a1aded7ca3a45fc46de4d2dbb1f4a38193f4f",
  withdrawalFee: "0",
  harvestInterval: "60",
  owner: "0x240c439011770253a379e4fcd391761071c06bfb",
  inputToken: {
    name: "B4Real",
    symbol: "B4RE",
    decimals: "18",
    address: "0x3c27564e3161bbaa6e7d2f0320fa4be77aed54da",
    tokenURL:
      "https://cryption-network-local.infura-ipfs.io/ipfs/QmYcftrjFV4qRGixg8FZekc4siPndaQyYX1oJoJ1U9ie2g",
    price: "0.50",
  },
  rewardToken: {
    name: "B4Real Credits",
    symbol: "B4RC",
    decimals: "18",
    address: "0x6be961cc7f0f182a58D1fa8052C5e92026CBEcAa",
    tokenURL:
      "https://cryption-network-local.infura-ipfs.io/ipfs/QmbCguTQzatdB3ebFVBq43B36g24e1DTSvPqw6YEpRG1ug",
    startBlock: "",
    endBlock: "",
    rewardsPerToken: "0.5",
    expectedAPR: "50",
    price: "1.00",
  },
};

export const NATIVE_TOKENS = {
  80001: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: "18",
  },
  137: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: "18",
  },
  1: {
    name: "ETH",
    symbol: "ETH",
    decimals: "18",
  },
  1287: {
    name: "Glimmer",
    symbol: "GLMR",
    decimals: "18",
  },
};
